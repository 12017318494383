.design_cart{
    background-color: #FFFFFF;
    height:auto;
    width: 100%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 10px;
    padding: 1rem;
    padding-top: 0.8rem !important;
    overflow: auto;
}

.black_line{
    width: 100%;
    background-color: black;
    height: 1rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}