@media screen and (max-width: 480px) and (min-width: 280px) {
  .InputLabel {
    font-weight: 400 !important ;
  }
}
@media screen and (max-width: 768px) and (min-width: 480px) {
  .InputLabel {
    font-weight: 400 !important ;
  }
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .InputLabel {
    font-weight: 400 !important ;
  }
}
