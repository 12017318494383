.main-container-home {
  background-color: rgb(22, 14, 14);
  background-repeat: no-repeat;
  /* background-image: linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url("../../../public/truckNew.jpg"); */
  background-size: cover;
  background-position: center center;
  width: 100%;
  text-align: center;
  color: #fff;
}
@media only screen and (max-width: 1326px) {
  .main-container-home {
    height: 100% !important;
  }
}
/* @media screen and (min-width: 786px) and (max-width: 1024px) { */
.main-body {
  height: calc(100vh - 11vh);
}
@media screen and (min-width: 200px) and (max-width: 280px) {
  .heading {
    margin-bottom: 0rem;
  }
}

.suggestion__box {
  padding-left: 1rem;
  padding-bottom: 0.5rem;
  position: absolute;
  top: 37px;
  background: white;
  width: 100%;
  display: flex;
}
