@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cardo&family=Inter:wght@600;700&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  background-color: #fcfcfc;
  color: black;
}

.active {
  border-bottom: 1px solid white;
}

.container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.EffectShow {}

.Journey:hover>.EffectShow {
  display: block;
}

.Journey {
  cursor: pointer;
}

.navbar {
  background: transparent;
  backdrop-filter: blur(10px);
  backdrop-filter: 20px;
  position: relative;
}

.logo {}

.menu-icon {
  display: none;
}

.nav-elements {}

.text-xs {
  /* position: absolute; */
  /* width: 160px; */
  /* height: 15px;
left: 27px;
top: 38px; */

  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  /* identical to box height */

  color: rgba(0, 0, 0, 0.5);
}

.text-all {



  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 16px;

  /* identical to box height, or 73% */

  letter-spacing: 0.005em;

  /* Black 8 */
  color: rgba(0, 0, 0, 0.8);
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}

.nav-elements ul a {
  font-family: var(--header-font);
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: #574c4c;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c;
}

.dropdown:hover>.dropdownItems {
  display: block;
}

.dropdownItems {
  display: none;
}

.DropDown:hover .dropdown-content {
  display: initial;
  background-color: red;
  z-index: 9999 !important;
}

@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
}

@media (max-width: 600px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 60px;
    background-color: #00000053;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 30%;
  }

  .signUp-btn {
    color: black !important;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }
}



/* respoinsive navbar */
.topnav {
  overflow: hidden;
  background-color: #333;
}

.topnav a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.active {
  background-color: #333 !important;
  color: white !important;
}

.topnav .icon {
  display: none;
}

.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown .dropbtn {
  font-size: 17px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

/* .topnav a:hover,
.dropdown:hover .dropbtn {
  background-color: #555;
  color: white;
} */

/* .dropdown-content a:hover {
  background-color: #ddd;
  color: black;
} */

.dropdown:hover .dropdown-content {
  display: block;
}

@media screen and (max-width: 1023px) {

  .topnav a:not(:first-child),
  .dropdown .dropbtn {
    display: none;
  }

  .topnav a.icon {
    float: right;
    display: block;
  }
}

@media screen and (max-width: 1023px) {
  .topnav.responsive {
    position: relative;
  }

  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }

  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }

  .topnav.responsive .dropdown {
    float: none;
  }

  .topnav.responsive .dropdown-content {
    position: relative;
  }

  .topnav.responsive .dropdown .dropbtn {
    display: block;
    width: 100%;
    text-align: left;
  }
}