.Main-Container {
  background-color: #e7e2e245;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  /* flex-direction: column; */
  align-items: center;
}
.Main-Title {
  width: 100%;
  /* font-family: "Cardo", serif; */
  /* font-family: var(--header-font); */
  font-family: "'Cardo', serif";
  color: #2f2032;
  padding-block: 1rem;
  font-weight: 700;
  line-height: 35px;
}
.Main-Heading {
  font-family: var(--header-font);
}

.maindiv {
  box-shadow: -2px 2px 2px #8080805c;
  border: 1px solid #80808024;
  padding-inline: 10rem;
  /* border: 1px solid #e7e7e7; */
  background-color: #ffffff;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45%;
  padding-block: 2rem;
}
.para-intro {
  font-size: 14px;
  width: 100%;
  font-family: var(--para-font);
  font-weight: 600;
  color: rgba(0, 0, 0, 0.5);
}
.E-mail,
.password {
  margin-bottom: 0.5rem;
  padding-left: 0px !important;
  padding-top: 1.5rem;
  padding-bottom: 0.75rem;
  font-family: "Inter";
  font-weight: 400;
  line-height: 21.78px;
  color: rgba(0, 0, 0, 1);
}
label {
  font-weight: 700;
  font-family: "Inter";
}
.input-Item {
  width: 100%;
  padding: 0.75rem 1rem;
  border-radius: 5px;
  border: none;
  border: 1px solid #b2b2b2;
  /* outline: none; */
}
.Remember-Me {
  color: rgba(0, 0, 0, 0.6);
  font-family: var(--para-font);
  font-weight: 600;
  font-size: 12px;
  display: flex;
}
.Forget-Password {
  text-decoration: none;
  font-family: var(--para-font);
  font-weight: 600;
  font-size: 12px;
  color: #000000;
  cursor: pointer;
}
.Sign-up {
  font-family: "Inter";
  font-weight: 400;
  line-height: 21.78px;
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  padding-top: 16px;
}
@media screen and (max-width: 600px) and (min-width: 280px) {
  .boxMain {
    display: flex;
    flex-direction: column;
    gap: 3.5rem;
    width: 100%;
    height: 100%;
  }
  .box-main- {
    background-color: #ffffff;
  }
  .container-show-data {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .inputs-for-login {
    font-weight: 400 !important;
    width: 100%;
  }
  .SignUpAccount {
    margin-top: 1.1875rem;
  }
}
@media screen and (max-width: 768px) and (min-width: 600px) {
  .boxMain {
    width: 87%;
  }
  .box-main- {
    background-color: #000000;
  }
  .inputs-for-login {
    font-weight: 400 !important;
    width: 100%;
  }
  .container-show-data {
    padding-inline: 8rem;
  }
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .boxMain {
    width: 87%;
  }
  .box-main- {
    background-color: #000000;
  }
  .inputs-for-login {
    font-weight: 400 !important;
    width: 100%;
  }
  .container-show-data {
    padding-inline: 8rem;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1400px) {
  .boxMain {
    width: 62%;
  }
}
