.heading_with_text___heading {
    font-size: 1.5rem;
    font-weight: 700;
    font-family: 'Cardo';
    font-style: normal;
}

.heading_with_text___heading--update {
    font-family: 'Cardo';
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
}

.heading_with_text___para {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.563rem;
    text-align: justify;
    color: #000000;
}