/* tracker */
.statusIcon {
  position: relative;
}

.statusIcon::after {
  content: "";
  position: absolute;
  width: 0px;
  height: 3.2rem;
  border: 1px dashed rgb(145, 145, 145);
  border: 1px dashed black;
  bottom: -49px;
  left: 24px;
}

.statusIcon:nth-last-child()::after {
  display: none !important;
}

@media only screen and (max-width: 1056px) {
  .tracker_main_wrapper {
    width: 100% !important;
  }
}

@media only screen and (max-width: 950px) {
  .track_top_section {
    flex-direction: row;
    justify-content: space-between ;
    align-items: center ;
    padding-bottom: 0.8rem;
  }
}

@media only screen and (max-width: 904px) {
  .track_top_section {
    flex-direction: row ;
    justify-content: space-between ;
    align-items: center ;
    padding-bottom: 0.8rem;
  }

  .button_first_to_last {
    margin-left: 0 !important;
    margin-top: 1rem;
  }
}

@media only screen and (max-width: 722px) {
  .city {
    margin-right: 1rem;
  }
}

.refresh_whenclick {
  background: none;
  border: 1px solid black;
  border-radius: 5px;
  padding: 0.3rem 2.7rem;
}

.heading_whenclick {
  font-size: 1.5rem;
  font-weight: 700;
  font-family: 'Cardo';
  font-style: normal;
}

.whenclick_bottom {
  padding: 2rem;
  width: 73.5% !important;
  margin: auto;
  background: none;
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  border-top-right-radius: 0rem !important;
  border-top-left-radius: 0rem !important;
}

.whenclick_bottom_heading {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  color: #FFFFFF;
  letter-spacing: 1px;
}

.whenclick_bottom_para {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300 !important;
  font-size: 0.7rem;
  color: #FFFFFF;
  letter-spacing: 1px;
}

.tooper-black {
  background: none !important;
  width: 73.5%;
  margin: 3rem auto;
  background: white !important;
  border-bottom-left-radius: 0.4rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 0.4rem;
  padding: 1.5rem;
}

.whenclick_bottom_heading {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  color: black;
  letter-spacing: 1px;
}

.whenclick_bottom_para {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300 !important;
  font-size: 0.7rem;
  color: black;
  letter-spacing: 1px;
}

/* tracker end */
.whenclick_bottom_for_track_page {
  position: absolute;
  bottom: 0;
}

.only_track {
  height: 55vh;
  padding-bottom: 3rem;
}

.pop_up_main_wrapper {
  background: rgba(255, 255, 255, 0.651);
  width: 100%;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.main_popup_1 {
  background-color: white;
  width: 30%;
  height: auto;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 1rem;
  border-radius: 0.3rem;
}
.main_popup_2 {
  background-color: white;
  width: 24%;
  height: fit-content;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 1rem;
  border-radius: 0.3rem;
}

.track_popup1_mess {

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  text-align: center;
  width: 60%;
  margin: 1rem auto;
}
.track_popup1_mess2 {

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  width: 60%;
  margin: 0;
}

.track_whenclick_button {
  background-color: #232323;
  color: white;
  padding: 0.3rem 3rem;
  font-family: 'Cardo';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 0.02em;
}