.subscription {
  margin-left: -70%;
  font-size: 22px;
  font-family: 'Cardo';
  font-style: normal;
  font-weight: 400;
}
.box {
  box-sizing: border-box;

  position: relative;
  width: 700px;
  height: 46px;
  left: 0px;
  /* margin-left: -13%; */
  /* top: 30px; */

  border: 2px solid rgba(0, 0, 0, 0.7);
  border-radius: 5px;
}
.daily-track {
  /* position: absolute; */
  width: auto;
  height: 19px;
  margin: 2%;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: #000000;
}
.list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  /* position: absolute; */
  width: auto;
  height: 144px;
  left: 365px;
  top: 636px;
  margin-left: -42%;
}
.parent-elem  .gap-4{
    gap:22rem;
    /* margin-top: 5rem; */
}