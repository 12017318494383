.pop_up_main_wrapper_ongoing {
    background: rgba(255, 255, 255, 0.651);
    width: 100%;
    height: 100% !important;
    position: absolute;
    top: 0;
    left: 0;
    backdrop-filter: blur(3px);
    display: flex;
    justify-content: center;
    /* align-items: flex-start; */
    padding-top: 15rem;
  }

  .pop_container_feedback{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }