.contact_main_wrapper {
    padding: 0.5rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
    padding-right: 0;
}

.container_contact {
    width: 100%;
    height: 75vh;
}

.contact_left {
    width: 40%;
    background: black;
    padding: 1rem;
    border-radius: 10px;
}

.contact_right {
    width: 60%;
}

.left_heading_contact {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    color: #FAFAFA;
}

.left_para_contact {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 27px;
    color: #C9C9C9;
}

.contact-social:hover {
    background-color: white !important;
    color: #1B1B1B;
}

html>body>div>section:nth-of-type(2)>div>div:nth-of-type(2)>div:nth-of-type(3)>div>div>label:first-of-type>span:nth-of-type(2) {
    font-size: 0.8rem !important;
}

html>body>div>section:nth-of-type(2)>div>div:nth-of-type(2)>div:nth-of-type(3)>div>div>label:nth-of-type(2)>span:nth-of-type(2) {
    font-size: 0.8rem !important;
}

html > body > div > section:nth-of-type(2) > div > div:nth-of-type(2) > div:nth-of-type(3) > div > div > label:nth-of-type(3) > span:nth-of-type(2) {
    font-size: 0.8rem !important;
}

html > body > div > section:nth-of-type(2) > div > div:nth-of-type(2) > div:nth-of-type(3) > div > div > label:nth-of-type(4) > span:nth-of-type(2){
    font-size: 0.8rem !important;
}

.textarea_contact::placeholder{
    font-weight: 300 !important;
}
.textarea_contact{
    outline: none !important;
}

.contact_button{
    background:#232323;
    color: white;
    padding: 0.8rem 1.3rem;
}

.circleBig::after {
    position: absolute;
    content: "";
    bottom: -106px;
    right: -87px;
    width: 269px;
    height: 269px;
    background: #1A1A1A;
    border-radius: 50%;
}

.circleSmally::before{
    position: absolute;
    content: "";
    bottom: 74px;
    right: -87px;
    width: 138px;
    height: 138px;
    background: rgba(72, 72, 72, 0.5);;
    border-radius: 50%;
}

html > body > div > div:nth-of-type(2) > div > section{
    height: 57vh;
}

.accordian_wrapper {
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}