.forget-heading {
  font-family: "Cardo", serif;
}
.para {
  font-family: "inter", serif;
  font-weight: 600;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}
.E-main-forget-Password {
  margin-bottom: 0.5rem;
  padding-left: 0px !important;
  padding-top: 1.5rem;
  padding-bottom: 0.75;
  font-family: "Inter";
  font-weight: 400;
  line-height: 21.78px;
  color: rgba(0, 0, 0, 1);
}
.email-input {
  width: 100%;
  padding: 0.75rem 1rem;
  border-radius: 5px;
  border: none;
  border: 1px solid #b2b2b2;
}
.main-Cointainer-head {
  margin: auto;
  box-shadow: -2px 2px 2px #8080805c;
  border: 1px solid #80808024;
  padding: 0rem;
  background-color: #ffffff;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45%;
}
.forget-pass-signUp {
  font-family: "Inter";
  /* font-weight: 400; */
  line-height: 21.78px;
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  padding-top: 16px;
}
.password {
  margin-bottom: 0.5rem;
  padding-left: 0px !important;
  padding-top: 1.5rem;
  padding-bottom: 0.75rem;
  font-family: "Inter";
  font-weight: 400;
  line-height: 21.78px;
  color: rgba(0, 0, 0, 1);
}

@media screen and (max-width: 600px) and (min-width: 280px) {
  .boxMain {
    display: flex;
    flex-direction: column;
    gap: 3.5rem;
    width: 100%;
    height: 100%;
  }
  .box-main- {
    background-color: #ffffff;
  }
  .container-show-data {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .inputs-for-login {
    font-weight: 400 !important;
    width: 100%;
  }
  .SignUpAccount {
    margin-top: 1.1875rem;
  }
}

@media screen and (max-width: 768px) and (min-width: 600px) {
  .boxMain {
    width: 87%;
  }
  .box-main- {
    background-color: #000000;
  }
  .inputs-for-login {
    font-weight: 400 !important;
    width: 100%;
  }
  .container-show-data {
    padding-inline: 8rem;
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .boxMain {
    width: 87%;
  }
  .box-main- {
    background-color: #000000;
  }
  .inputs-for-login {
    font-weight: 400 !important;
    width: 100%;
  }
  .container-show-data {
    padding-inline: 8rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1400px) {
  .boxMain {
    width: 62%;
  }
}
