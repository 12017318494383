/* tracker */
.statusIcon1 {
    position: relative;
  }
  
  .statusIcon1::after {
    content: "";
    position: absolute;
    width: 0px;
    height: 3.2rem;
    border: 1px dashed black;
    border: 1px dashed black;
    bottom: -46px;
    left: 24px;
  }
  
  .statusIcon1:nth-last-child()::after {
    display: none !important;
  }
  
  @media only screen and (max-width: 1056px) {
    .tracker_main_wrapper1 {
      width: 100% !important;
    }
  }
  
  @media only screen and (max-width: 950px) {
    .track_top_section {
      flex-direction: row;
      justify-content: space-between ;
      align-items: center ;
      padding-bottom: 0.8rem;
    }
  }
  
  @media only screen and (max-width: 904px) {
    .track_top_section {
      flex-direction: row ;
      justify-content: space-between ;
      align-items: center ;
      padding-bottom: 0.8rem;
    }
  
    .button_first_to_last {
      margin-left: 0 ;
      margin-top: 1rem;
    }
  }
  
  @media only screen and (max-width: 722px) {
    .city1 {
      margin-right: 1rem;
    }
  }
  
  .refresh_whenclick {
    background: none;
    border: 1px solid black;
    border-radius: 5px;
    padding: 0.3rem 2.7rem;
  }
  
  .heading_whenclick {
    font-size: 1.5rem;
    font-weight: 700;
    font-family: 'Cardo';
    font-style: normal;
  }
  
  .whenclick_bottom {
    padding: 2rem;
    width: 48%;
    margin: auto;
    background: #232323;
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
  }
  
  .whenclick_bottom_heading {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    color: #FFFFFF;
    letter-spacing: 1px;
  }
  
  .whenclick_bottom_para {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300 !important;
    font-size: 0.7rem;
    color: #FFFFFF;
    letter-spacing: 1px;
  }
  
  .tooper-black {
    background: #232323;
    width: 73.5%;
    margin: 3rem auto;
    background: #232323;
    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
    padding: 1.2rem 1rem;
  }
  
  /* .whenclick_topper {
    width: 73.5%;
    margin: auto;
    background: #232323;
    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
  } */
  
  .whenclick_bottom_heading {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    color: #FFFFFF;
    letter-spacing: 1px;
  }
  
  .whenclick_bottom_para {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300 !important;
    font-size: 0.7rem;
    color: #FFFFFF;
    letter-spacing: 1px;
  }
  
  /* tracker end */
  .whenclick_bottom_for_track_page {
    position: absolute;
    bottom: 0;
  }
  
  .only_track1 {
    height: 55vh;
    padding-bottom: 3rem;
  }

  .width-for-toll-bottom-main{
    width: 55%;
  }