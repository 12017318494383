body {
  background-color: #fafafa;
}

label {
  font-family: "Cardo";
  font-weight: 400;
}

/* ongoing popup */

.pop_up_main_wrapper {
  background: rgba(255, 255, 255, 0.651);
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
}

.main_popup_1ss {
  background-color: white;
  width: 30%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 1rem;
  margin-top:-10rem !important;
  border-radius: 0.3rem;
}

.track_popup1_mess {

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  text-align: center;
  width: auto !important;
  margin: 1rem auto;
}

.journey_button_pop {
  background-color: #232323;
  color: white;
  padding: 0.3rem 3rem;
  font-family: 'Cardo';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 0.02em;
  width: 100%;
}

.plan_journey_pop_para {
  font-family: 'Cardo';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 44px;
}

.popup__row1_h1{
  font-size: 1rem;
  font-weight: 500;
}

.popup__row1_span1{
  font-size: 0.8rem;
  font-weight: 400;
}

.dashed_line{
  width: 100%;
  border-top: 1px dashed #FFFFFF;
}


