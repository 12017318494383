.wallet-cont {
  /* box-shadow: 0px 10px 20px rgba(0, 40, 100, 0.25); */
  margin-right: 1%;
  border: 0.4px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
.h-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
}
.p-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
}
.table-heading {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  margin: 5% 0% 0% 14%;
}
