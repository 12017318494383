@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Cardo&family=Inter:wght@600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth !important;
}

body {
  position: relative;
  height: 100% !important;
  scroll-behavior: smooth !important;
  z-index: ;
}

/* dark theme setting */
.dark__theme {
  background: #393646;
}

.arrow-btn {
  border: 0.2px solid #ffffff;
  /* border-radius: 0px 5px 5px 0px; */
}

.btn-arrow {
  margin-right: 0%;
}

.font-medium {
  font-weight: 600;
}

:root {
  --header-font: "Cardo", serif;
  --para-font: "Inter", sans-serif;
}

.tracker_main_container {
  overflow: scroll !important;
}

/* table  */
table {
  border-spacing: 0px !important;
}

td {
  padding-top: 0.1rem !important;
  padding-bottom: 0.1rem !important;
  padding-left: 1rem !important;
  border-bottom: 1px solid black;
  text-align: start !important;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.onGoing_table {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

th:first-child {
  border-top-left-radius: 0.5rem;
}

th:last-child {
  border-top-right-radius: 0.5rem;
}

th {
  text-transform: capitalize;
  font-weight: 400;
}

tr:last-child td {
  border: none;
}

tr:last-child td:first-child {
  border-bottom-left-radius: 0.5rem;
}

tr:last-child td:last-child {
  border-bottom-right-radius: 0.5rem;
}

th {
  text-align: start;
  padding-left: 1rem !important;
}

/* plan cards */

/* fisrt */
html
  > body
  > div
  > div
  > div:nth-of-type(2)
  > div:nth-of-type(3)
  > div
  > div:first-of-type {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}

/* second */
html
  > body
  > div
  > div
  > div:nth-of-type(2)
  > div:nth-of-type(3)
  > div
  > div:nth-of-type(2) {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}

html
  > body
  > div
  > div
  > div:nth-of-type(2)
  > div:nth-of-type(3)
  > div
  > div:nth-of-type(2)
  > div:nth-of-type(2)
  > button {
  background-color: #262626;
  color: white;
}

/* third card */
html
  > body
  > div
  > div
  > div:nth-of-type(2)
  > div:nth-of-type(3)
  > div
  > div:nth-of-type(3) {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}

/* 
html > body > div > div > div:nth-of-type(2) > div:nth-of-type(3) > div {
  padding-top: 3rem;
  padding-bottom: 3rem;
} */

html
  > body
  > div
  > div
  > div:nth-of-type(2)
  > div:nth-of-type(3)
  > div
  > div:first-of-type
  > div:nth-of-type(2)
  > button {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border: none;
  color: black;
  font-weight: 600;
}

html
  > body
  > div
  > div
  > div:nth-of-type(2)
  > div:nth-of-type(3)
  > div
  > div:nth-of-type(3)
  > div:nth-of-type(2)
  > button {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border: none;
  color: black;
  font-weight: 600;
  background: none;
}

.requst_bottom_para {
  font-size: 1.8rem;
  color: white !important;
}

.edit-width-refunpop {
  width: 27% !important;
  height: auto !important;
  padding-bottom: 3rem !important;
}

.edit-width-refunmess {
  width: 80% !important;
}

.edit-width-refunbutton {
  width: 100% !important;
}

.edit-width-refun_warpper {
  width: 85% !important;
  margin: auto !important;
}

.hoverOnwithoutBackButton {
  transition: all 0.5s;
}

.hoverOnwithoutBackButton:hover {
  background-color: #232323 !important;
  color: white !important;
}

.share_social_wrapper {
  width: 3rem;
  height: 3rem;
  background: #f8f9fa;
  border-radius: 50%;
}

/* hover button */
.hoverButoon:hover {
  background: none !important;
  color: #232323 !important;
  border: 1px solid #232323 !important;
}

/* black bottom border */
.border_bottpm_1px_black {
  border-bottom: 1px solid #4e4e4e;
}

/* table_more_dropdown */
.table_more_dropdown {
  display: none;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.statusIcon:last-child:after {
  display: none !important;
}

.vahicleNavDropdown:hover .filter-sec {
  display: none !important;
}

html
  > body
  > div
  > div
  > div:nth-of-type(3)
  > div
  > div
  > div
  > div:nth-of-type(3)
  > div:nth-of-type(2)
  > div:nth-of-type(2) {
  display: none !important;
}

html
  > body
  > div
  > div:nth-of-type(3)
  > div
  > div
  > div
  > div:nth-of-type(3)
  > div:nth-of-type(3)
  > div:nth-of-type(2) {
  display: none;
}

html
  > body
  > div
  > div:nth-of-type(3)
  > div
  > div
  > div
  > div:nth-of-type(5)
  > div:nth-of-type(2) {
  visibility: hidden !important;
}

/* responsive code by tarun */
@media screen and (max-width: 834px) {
  .form_journey_planeer_inner {
    width: 90% !important;
  }

  .track_top_section {
    width: 90% !important;
  }

  .whenclick_bottom {
    width: 90% !important;
  }
}

@media screen and (max-width: 1032px) {
  .filter_div {
    flex-direction: column;
  }

  .filter-sec {
    margin-left: 0 !important;
  }
}

@media screen and (max-width: 568px) {
  .track_top_section {
    flex-direction: column !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    padding-bottom: 0.8rem;
  }
}

@media screen and (max-width: 834px) {
  .onGoing_table {
    margin-top: 1rem !important;
  }

  .please_scroll {
    display: flex !important;
  }
}

/* please_scroll */
.please_scroll {
  animation-name: scrollPlesae;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes scrollPlesae {
  0% {
    transform: translateX(0px);
  }

  50% {
    transform: translateX(15px);
  }

  100% {
    transform: translateX(0px);
  }
}

@media screen and (max-width: 1068px) {
  .onGoing_table {
    margin-top: 1rem !important;
  }

  .please_scroll {
    display: flex !important;
  }
}

@media screen and (max-width: 600px) {
  .info__card {
    width: 93% !important;
  }
}

@media screen and (max-width: 1023px) {
  .enter_wrpper {
    display: none !important;
  }
}

@media screen and (max-width: 886px) {
  .enter_wrpper {
    display: none !important;
  }
}

@media screen and (max-width: 1000px) {
  .journey_planner_bottom {
    display: none !important;
  }
}

.hover_on_suggestion:hover {
  background-color: rgb(189, 252, 231);
}

@media screen and (max-width: 1023px) {
  .desktop_navbar_1 {
    display: none !important;
  }

  .qweerty_vd {
    margin-left: 0 !important;
  }
}

@media screen and (min-width: 1023px) {
  .topnav {
    display: none !important;
  }
}

.trans_nav {
  backdrop-filter: blur(10px);
  background-color: rgba(102, 101, 101, 0.308) !important;
}

@media screen and (min-width: 1023px) {
  .topnav {
    display: none !important;
  }
}

@media screen and (max-width: 931px) {
  .footer_web {
    height: auto !important;
  }

  .footer_container {
    flex-direction: column !important;
  }

  .footer_left {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 1326px) {
  .whenclick_bottom {
    width: 73.5% !important;
  }
}

@media screen and (max-width: 848px) {
  .whenclick_bottom {
    width: 95% !important;
  }

  .info__card {
    width: 95% !important;
  }

  .tooper-black {
    width: 95% !important;
  }

  .track_top_section {
    width: 95% !important;
  }
}

@media screen and (max-width: 740px) {
  .whenclick_bottom {
    flex-direction: column;
  }

  .whenclick_bottom_ifo {
    margin-bottom: 1rem !important;
  }
}

@media screen and (max-width: 628px) {
  .track_top_section {
    flex-direction: column !important;
  }

  .top_sec_left {
    width: 100% !important;
  }

  .top_sec_left {
    width: 100% !important;
  }

  .button_first_to_last {
    width: 100% !important;
  }

  .download_scv {
    text-align: center;
  }

  .qweerty_vd {
    margin-top: 1rem !important;
  }
}

@media screen and (max-width: 500px) {
  .whenclick_bottom {
    margin-bottom: 1rem !important;
    border-radius: 1rem !important;
  }

  .track_again_wrapper {
    flex-direction: column;
  }

  .track_again_wrapper_button_wrapper {
    margin-top: 1rem;
    width: 100% !important;
  }

  .track_again_wrapper_input_wrapper {
    width: 100% !important;
  }
}

@media screen and (max-width: 616px) {
  .auto_renew_wrapper {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .refresh_whenclick {
    margin-top: 1rem !important;
  }
}

@media screen and (max-width: 994px) {
  .main_popup_2 {
    width: 38% !important;
  }
}

@media screen and (max-width: 700px) {
  .main_popup_2 {
    width: 55% !important;
  }
}

@media screen and (max-width: 464px) {
  .main_popup_2 {
    width: 70% !important;
  }
}

@media screen and (max-width: 390px) {
  .main_popup_2 {
    width: 90% !important;
  }
}

@media screen and (max-width: 964px) {
  .main_popup_2 {
    width: 90% !important;
  }

  .main_popup_1 {
    width: 48% !important;
  }
}

@media screen and (max-width: 602px) {
  .main_popup_1 {
    width: 60% !important;
  }
}

@media screen and (max-width: 1094px) {
  .main_popup_1ss {
    width: 43% !important;
  }
}

@media screen and (max-width: 740px) {
  .main_popup_1ss {
    width: 55% !important;
  }
}

@media screen and (max-width: 584px) {
  .main_popup_1ss {
    width: 65% !important;
  }
}

@media screen and (max-width: 500px) {
  .main_popup_1ss {
    width: 75% !important;
  }
}

@media screen and (max-width: 426px) {
  .main_popup_1ss {
    width: 90% !important;
  }
}

.hashtagOfMain {
  font-family: "Tiro Devanagari Hindi", serif !important;
}

.overwrite_main_wrapper_popup {
  justify-content: center !important;
  align-items: flex-start !important;
  box-shadow: black 0px 7px 29px 0px !important;
}

.plan_card_card {
  transition: all 1s !important;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  transition: all 1s !important;
}

.plan_card_card:hover {
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.width_full {
  width: 100% !important;
}

.plan_mai_warap {
  height: fit-content !important;
}

@media screen and (max-width: 752px) {
  .row_row {
    flex-direction: column !important;
    margin: 0 !important;
  }

  .button_group_journeyplanner {
    margin-top: 1rem;
  }

  .row_row > div {
    width: 100% !important;
    margin-top: 1rem;
  }
}

@media screen and (max-width: 653px) {
  .vehicle_add_form {
    width: 90% !important;
  }
}

@media screen and (max-width: 926px) {
  /* .ffffsgsgsgsgsghdwsydxgy {
    flex-direction: column !important;
  } */
  .plan_cardy {
    width: 49% !important;
  }
}

@media screen and (max-width: 630px) {
  .ffffsgsgsgsgsghdwsydxgy {
    flex-direction: column !important;
  }

  .plan_cardy {
    width: 100% !important;
    margin-top: 1rem;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
  }

  .shisuidsidhs {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
  }

  .contact_us_bottmer {
    width: 75% !important;
  }
}

.input_popup_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100%; */
  background-color: #ffffff08;
  backdrop-filter: blur(15px);
  align-items: flex-start !important;
  padding-top: 10rem;
}

.suggestion_boxing::-webkit-scrollbar {
  display: block !important;
  width: 1rem;
}

html
  > body
  > div:first-of-type
  > div:nth-of-type(3)
  > section
  > div
  > section
  > div
  > h1 {
  margin-top: 3rem !important;
}

@media screen and (max-width: 1010px) {
  .available_container {
    flex-direction: column !important;
  }

  .available_wrapper__right {
    margin-top: 1rem !important;
  }
}

@media screen and (max-width: 550px) {
  .sub_header > p {
    font-size: 0.8rem !important;
    text-align: center !important;
  }

  #nav-iteam3 {
    padding-right: 60px !important;
  }
}

@media screen and (max-width: 800px) {
  html > body > div:first-of-type > section:nth-of-type(2) {
    width: 100% !important;
  }
}

@media screen and (max-width: 1024px) {
  html > body > div:first-of-type > div:first-of-type > section {
    width: 90% !important;
  }

  html > body > div:first-of-type > section {
    width: 100% !important;
  }

  .ProfileNav {
    width: 90% !important;
    border-radius: 0rem !important;
  }

  .zxcvbb {
    margin-top: 1rem !important;
  }
}

/* plan_buy_confirmation */
.plan_buy_confirmation {
  background-color: rgba(173, 173, 173, 0.308);
  backdrop-filter: blur(10px);
  display: flex;
  width: 100% !important;
  justify-content: center;
  align-items: flex-start;
}

.plan_buy_confirmation__wrapper {
  background-color: #ffffff;
  width: 29%;
  margin-top: 10rem;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

#error_popups {
  background-color: rgba(156, 156, 156, 0.534);
  backdrop-filter: blur(10px);
}

/* whenclick_bottom_tollPlaza */
.whenclick_bottom_tollPlaza {
  width: 73.5% !important;
  margin: auto;
  background: #232323;
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  border-top-right-radius: 0rem !important;
  border-top-left-radius: 0rem !important;
}

.five_sec_popup_show {
  position: absolute !important;
  top: 0.5rem;
  padding: 1rem;
  border-radius: 0.3rem;
  left: 0rem;
  transition: all 0.5s;
}

/* ori_suggestion */
.ori_suggestion {
  background: white;
  position: absolute;
  width: 100%;
  height: auto;
  max-height: 5rem;
  overflow: auto;
}

.origin_jp:hover {
  background-color: rgb(192, 252, 249);
}

.destination_jp:hover {
  background-color: rgb(192, 252, 249);
}

.inner_shad {
  box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset,
    rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
  border: none;
  outline: none;
}

.innerMAgix {
  box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset,
    rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
  padding: 0.5rem;
  border-radius: 0.2rem;
}

.trackAgain {
  transition: all 0.5s !important;
}

.trackAgain:hover {
  background-color: #232323 !important;
  color: white !important;
}

.plan_card_card {
  transition: all 1s !important;
}

.plan_card_card:hover {
  box-shadow: 0 0 0 2px #333333, 8px 8px 0 0 #333333 !important;
}

.ncdbottom {
  transition: all 0.5s;
}

.ncdbottom:hover {
  box-shadow: 0 0 0 2px #333333, 8px 8px 0 0 #333333 !important;
}

.VIpgJd-ZVi9od-l4eHX-hSRGPd {
  display: none !important;
}

select.goog-te-combo:nth-child(2) {
  display: none;
}

#google_translate_element {
  display: flex;
  justify-content: flex-end;
  padding-right: 1rem;
}

/* 
html>body>div:first-of-type>div:first-of-type>div:first-of-type>div:nth-of-type(2) {
  display: none !important;
} */

.goog-te-gadget {
  font-size: 0 !important;
}

.goog-te-combo {
  width: 5rem !important;
}

@media screen and (max-width: 479px) {
  .intro-para {
    font-size: 9px !important;
  }

  .f_link {
    font-size: 14px !important;
  }
}

#getVehicleButton {
  transition: all 0.5s !important;
}

#getVehicleButton:hover {
  background-color: #232323 !important;
  color: white !important;
}

@media screen and (max-width: 840px) {
  .track_toppere {
    width: 90% !important;
  }

  .track_toppere {
    display: flex;
    flex-wrap: wrap !important;
  }

  html > body > div:first-of-type > section:nth-of-type(2) {
    width: 90% !important;
  }
}

@media screen and (max-width: 600px) {
  .track_toppere {
    display: flex;
    flex-direction: column;
  }

  .track_small_detail {
    box-shadow: rgb(204, 204, 204) 3px 3px 6px 0px inset,
      rgb(204, 204, 204) -3px -3px 6px 1px inset;
    padding-left: 0.2rem;
  }
}

@media screen and (max-width: 500px) {
  .statusIcon {
    width: 14% !important;
  }

  .statusIconLast {
    width: 14% !important;
  }

  .city {
    width: 30% !important;
  }
}

@media screen and (max-width: 377px) {
  .statusIcon {
    width: 17% !important;
  }

  .statusIconLast {
    width: 17% !important;
  }

  .city {
    width: 50% !important;
  }
}

@media screen and (max-width: 445px) {
  .requst_bottom_para {
    font-size: 1.2rem;
  }
}

.sub_desc_box {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

/* styled button START*/
.button-86 {
  all: unset;
  width: 100px;
  height: 30px;
  font-size: 16px;
  background: transparent;
  border: none;
  position: relative;
  color: #f0f0f0;
  cursor: pointer;
  z-index: 1;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-86::after,
.button-86::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -99999;
  transition: all 0.4s;
}

.button-86::before {
  transform: translate(0%, 0%);
  width: 100%;
  height: 100%;
  background: #28282d;
  border-radius: 10px;
}

.button-86::after {
  transform: translate(10px, 10px);
  width: 35px;
  height: 35px;
  background: #ffffff15;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 50px;
}

.button-86:hover::before {
  transform: translate(5%, 20%);
  width: 110%;
  height: 110%;
}

.button-86:hover::after {
  border-radius: 10px;
  transform: translate(0, 0);
  width: 100%;
  height: 100%;
}

.button-86:active::after {
  transition: 0s;
  transform: translate(0, 5%);
}

.track_my_goods {
  min-height: 46vh;
}

/* styled button END */

::placeholder {
  font-size: 0.8rem;
}

#website_table {
  z-index: 9 !important;
}

#ongoing_id {
  overflow: visible !important;
}

.tracker_main_container {
  margin-top: 2.5rem !important;
}

.height_adjus {
  min-height: 0 !important;
}

/* responsive profile page */
@media screen and (max-width: 930px) {
  .profile_main_section {
    width: 90% !important;
  }
}

@media screen and (max-width: 792px) {
  .vgudkssds_01 {
    flex-direction: column;
  }

  .distra {
    width: 100% !important;
  }

  .distra1 {
    width: 100% !important;
    margin-top: 0.5rem;
  }
}

html
  > body
  > div:first-of-type
  > div:first-of-type
  > main:nth-of-type(3)
  > div
  > div:nth-of-type(2)
  > div:nth-of-type(2)
  > div:first-of-type
  > div
  > input {
  width: 100% !important;
}

html
  > body
  > div:first-of-type
  > div:first-of-type
  > main:nth-of-type(3)
  > div
  > div:nth-of-type(2)
  > div:nth-of-type(2)
  > div:nth-of-type(2)
  > div
  > div
  > input {
  width: 100% !important;
}

html
  > body
  > div:first-of-type
  > div:first-of-type
  > main:nth-of-type(3)
  > div
  > div:nth-of-type(3)
  > div
  > input {
  width: 100% !important;
}

html
  > body
  > div:first-of-type
  > div:first-of-type
  > main:first-of-type
  > div
  > div:nth-of-type(3)
  > div:nth-of-type(3)
  > input {
  width: 100% !important;
}

@media screen and (max-width: 500px) {
  input {
    font-size: 0.8rem !important;
  }

  textarea {
    font-size: 0.8rem !important;
  }
}

@media screen and (max-width: 700px) {
  .email_phone_sec {
    flex-direction: column !important;
  }

  .inputItem {
    width: 100%;
  }

  .passowddsdsdw {
    flex-direction: column !important;
  }

  .vgvsdcouisifxx {
    width: 100% !important;
  }
}

#price_table {
  width: 100%;
  margin-bottom: 0.3rem;
  background: white;
  margin-top: -0.6rem;
}

#price_table > thead > tr > td {
  border: 1px solid rgb(223, 223, 223) !important;
  font-weight: bold;
}

#price_table > tbody > tr > td {
  border: 1px solid rgb(223, 223, 223) !important;
}

@media screen and (max-width: 700px) {
  .mobile_planbtn_home {
    width: 100% !important;
    padding: 0.1rem !important;
    height: 2.5rem !important;
  }
}

@media screen and (max-width: 500px) {
  .cjhdhgfuehfudjs {
    width: 100% !important;
    margin-left: 0rem !important;
  }

  .Main-Heading {
    width: 100% !important;
    text-align: start !important;
  }

  .ffgfgfgfgfgf {
    margin-left: 0 !important;
  }
}

@media screen and (max-width: 500px) {
  .fygaeojjj {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@media screen and (max-width: 614px) {
  .fygaeojjj {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .refresh_whenclick {
    width: 100% !important;
    justify-content: center !important;
  }
}

.padding-sunya {
  padding: 0 !important;
}

@media screen and (max-width: 1000px) {
  .left_middle {
    flex-direction: column !important;
    align-items: flex-start !important;
  }

  .vvvvvbbbb {
    margin-top: 1rem;
  }

  .container_contact {
    height: 80vh !important;
  }

  .contact_main_wrapper {
    width: 95% !important;
  }
}

.tracker_row:first-child {
  margin-top: 0rem !important;
}

.tracker_main_container_remove {
  margin-top: 0 !important;
}
.mlpotrewazxcv {
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
}

/* 1015 */
@media screen and (min-width: 1015px) {
  .tracker_main_container_remove {
    margin-top: 0 !important;
  }
}

@media screen and (max-width: 600px) {
  .gggggdfefsdnvbx {
    width: 90% !important;
  }
  .diks {
    font-size: 0.7rem !important;
  }
  .diks2 {
    font-weight: bolder;
  }
}

.vertical-shake {
  animation: vertical-shaking 1s infinite;
}

@keyframes vertical-shaking {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(5px);
  }
  50% {
    transform: translateY(-5px);
  }
  75% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
  }
}

.control_gap {
  padding-top: 0 !important;
  margin-top: 0 !important;
  z-index: -9999999 !important;
}

.control_gap > table {
  margin-top: 0.5rem !important;
  z-index: -9999999 !important;
}
#website_table {
  z-index: -99 !important;
}

#table_after {
  z-index: 9 !important;
}

.control_gap {
  overflow: visible !important;
}

.kam_gap {
  margin-top: 0.5rem !important;
}

#qwernh {
  z-index: -9999999 !important;
}

.suggestion_box {
  background-color: #ffffffa6 !important;
}

@media screen and (max-width: 1015px) {
  .firstButton {
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .suggestion__box{
    z-index: 999999999999999 !important;
  }
}

.MuiTabs-flexContainer{
  display: flex !important;
  flex-wrap: wrap !important;
}


@media screen and (max-width: 700px) {
.second-Section{
  flex-direction: column;
}
.accept-terms{
  font-size: 10px !important;
}
.mobile-register-buttons{
  flex-direction: column;
}
.for_lo{
  margin-top: 0.3rem;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
}
.mhdiv{
  display: none;
}
#hdfguoassj{
  font-size: 15px;
}
.asdfghjhgf{
  font-size: 10px;
}
.logoM{
  width: 4rem !important;
  height:3rem !important;
}
.mlsjsk{
  margin-top: 1rem !important;
}
.mobile_planbtn_home {
	width: 100% !important;
	padding: 0.2rem !important;
	height: 3rem !important;
}
}

#copyInput{
  display: none;
}
#copy_lab{
  display: none;
}

@media screen and (max-width: 700px) {
  #copyInput{
    display: block;
    width: 82%;
  }
  #copy_lab{
    display: block;
  }
  #copy_lab{
    width: 82%;
    display: block;
  }
  .only_mobile{
    display: block !important;
  }
  .only_mobile>input{
    border: none !important;
  }
  .only_desktop{
    display: none !important;
  }
}
