.blog{
    width: 73.5%;
    margin: auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.blog__card{
    width: 50%;
    padding: 1rem;
    height: 20rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.blog__viewing{
    padding-bottom: 2rem;
}
