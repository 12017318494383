.wallet {
  font-size: 22px;
  font-family: 'Cardo';
  font-style: normal;
  font-weight: 400;
}
.amount:hover {
  background-color: #ffffff;
  color: black;
  border: 0.4px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 10px 20px rgba(0, 40, 100, 0.25);
  border-radius: 4px;
}
.contact {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
}
